import { useMemo } from 'react';
import { useRouteLoaderData, useMatches } from '@remix-run/react';
import { loader, RootLoaderData } from '~/root';

export function useRootLoaderData(): RootLoaderData {
  return useRouteLoaderData<typeof loader>('root') as RootLoaderData;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
function useMatchesData(id: string): any {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id],
  );
  return route?.data;
}

export function useOptional<T>(id: string): T | undefined {
  const data = useMatchesData('root');
  return data[id] as T;
}

export function useRequired<T>(id: string): T {
  const maybeData = useOptional<T>(id);
  if (!maybeData) {
    throw new Error(
      `No data found for ${id} on root loader, but it is required.`,
    );
  }
  return maybeData;
}
